import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "deviceBatteryAdd",
  mixins: [mixins],
  data() {
    return {
      warehouses: [],
      //仓库
      batteryModes: [],
      edit: false,
      formModel: {
        id: null,
        batteryCode: null,
        batteryModeId: null,
        warehouseId: null,
        bmsManufacturer: null
      },
      formRules: {
        warehouseId: [{
          required: true,
          message: '请输入仓库名称',
          trigger: 'blur'
        }],
        batteryCode: [{
          required: true,
          message: '请输入电池编号',
          trigger: 'blur'
        }, {
          min: 5,
          max: 32,
          message: '长度在 5 到 32 个字符',
          trigger: 'blur'
        }],
        bmsManufacturer: [{
          required: true,
          message: '请输入电池组制造厂名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 64,
          message: '长度在 1 到 64 个字符',
          trigger: 'blur'
        }],
        batteryModeId: [{
          required: true,
          message: '请输入电池型号',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    // 查询仓库
    remoteWarehouse(query) {
      this.warehouses = [];
      if (query && query.length >= 1) {
        this.loading = true;
        this.$api.business.carWarehouse.getList({
          page: 1,
          size: 10,
          name: query
        }).then(res => {
          if (res.code === 200) {
            this.warehouses = res.data.records;
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    modeController(query) {
      this.batteryModes = [];
      if (query && query.length >= 1) {
        this.loading = true;
        this.$api.business.batteryModel.getList({
          page: 1,
          size: 10,
          name: query
        }).then(res => {
          if (res.code === 200) {
            this.batteryModes = res.data.records;
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          const callBack = res => {
            loading.close();
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.deviceBattery.update(this.formModel).then(callBack);
          } else {
            this.$api.business.deviceBattery.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.deviceBattery.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
          this.batteryModes = [{
            id: res.data.batteryModeId,
            name: res.data.batteryModeName
          }];
          this.warehouses = [{
            id: res.data.warehouseId,
            name: res.data.warehouseName
          }];
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    } else {
      this.formModel = {
        id: null,
        batteryCode: null,
        batteryModeId: null,
        warehouseId: null
      };
    }
  }
};